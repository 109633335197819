export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "FIELDS": {
          "FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio"])},
          "DELIVERY_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Entrega"])},
          "SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor"])},
          "SUPPLIER_FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio de Compra del Proveedor"])},
          "CREDIT_DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días de Crédito"])},
          "FREIGHT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Carga"])},
          "OBSERVATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observaciones"])}
        }
      }
    }
  })
}
